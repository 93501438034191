import {  useEffect, useState } from 'react';
import { Paper, Table } from '@mantine/core';
import { usePatient } from '../hooks/usePatient';
import { Observation } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { formatDateTime } from '@medplum/core';

const BloodPressureHistory: React.FC = () => {
  // Sample data, replace with actual data fetching logic
  const patient = usePatient();
  const medplum = useMedplum();
  const [bloodPressureReadings, setBloodPressureReadings] = useState<Observation[]>([]);

  useEffect(() => {
    if (patient) {
      medplum
        .searchResources(
          'Observation',
          {
            patient: patient?.id,
            code: 'http://loinc.org|85354-9', // LOINC code for Blood Pressure Panel
            _sort: '-date'
          }
        )
        .then((response) => {
          setBloodPressureReadings(response);
        })
        .catch(console.error);
    }
  }, [medplum, patient]);

  if (!patient) {
    return null;
  }

  return (
    <Paper shadow="sm" >
      <h3>Blood Pressure History</h3>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Systolic (mmHg)</th>
            <th>Diastolic (mmHg)</th>
          </tr>
        </thead>
        <tbody>
          {bloodPressureReadings.map((bp) => {
            const systolic = bp.component?.find(
              (c) => c.code?.coding?.[0]?.code === '8480-6'
            )?.valueQuantity?.value;
            const diastolic = bp.component?.find(
              (c) => c.code?.coding?.[0]?.code === '8462-4'
            )?.valueQuantity?.value;

            return (
              <tr key={bp.id}>
                <td>{formatDateTime(bp.effectiveDateTime)}</td>
                <td>{systolic}</td>
                <td>{diastolic}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Paper>
  );
};

export default BloodPressureHistory;
