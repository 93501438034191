import { MantineColorsTuple, MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import '@medplum/react/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { getConfig } from './config';

const config = getConfig();

const medplum = new MedplumClient({
  baseUrl: config.baseUrl,
  clientId: config.clientId,
  onUnauthenticated: () => {
    if (window.location.pathname !== '/signin' && window.location.pathname !== '/oauth') {
      window.location.href = '/signin?next=' + encodeURIComponent(window.location.pathname + window.location.search);
    }
  },
  cacheTime: 60000,
  autoBatchTime: 100,
});

const orange: MantineColorsTuple = [
  '#fff0e3',
  '#ffe0cd',
  '#fbbf9f',
  '#f79e6d',
  '#f38041',
  '#f16e26',
  '#f16316',
  '#d75308',
  '#c04904',
  '#a83c00'
];

const theme = createTheme({
  primaryColor: 'orange',
  colors: {
    orange,
  },
  primaryShade: 4,
  fontFamily: 'Urbanist, sans-serif',
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
  },
  components: {
    Container: {
      defaultProps: {
        size: 1200,
      },
    },
  },
});


const router = createBrowserRouter([{ path: '*', element: <App /> }]);

const navigate = (path: string): Promise<void> => router.navigate(path);

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <MedplumProvider medplum={medplum} navigate={navigate}>
      <MantineProvider theme={theme}>
        <Notifications position="bottom-right" />
        <RouterProvider router={router} />
      </MantineProvider>
    </MedplumProvider>
  </StrictMode>
);
