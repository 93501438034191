import { Title } from '@mantine/core';
import { Logo, SignInForm, useMedplumProfile } from '@medplum/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from '../config';
import { useCallback, useEffect } from 'react';

export function SignInPage(): JSX.Element {
  const profile = useMedplumProfile();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToNext = useCallback(() => {
    // only redirect to next if it is a pathname to avoid redirecting
    // to a maliciously crafted URL, e.g. /signin?next=https%3A%2F%2Fevil.com
    const nextUrl = searchParams.get('next');
    navigate(nextUrl?.startsWith('/') ? nextUrl : '/');
  }, [searchParams, navigate]);

  useEffect(() => {
    if (profile && searchParams.has('next')) {
      navigateToNext();
    }
  }, [profile, searchParams, navigateToNext]);

  return (
    <SignInForm
      // Configure according to your settings
      // googleClientId="921088377005-3j1sa10vr6hj86jgmdfh2l53v3mp7lfi.apps.googleusercontent.com"
      clientId={config.clientId}
      projectId={config.projectId}
      // googleClientId={config.googleClientId}
      onSuccess={() => navigateToNext()}
      onForgotPassword={() => navigate('/resetpassword')}
    >
      <Logo size={32} />
      <Title>Sign in to Ramona Provider App</Title>
    </SignInForm>
  );
}
